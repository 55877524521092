import Vue from 'vue'
import { Button, Tabbar, TabbarItem, Cell, CellGroup, Icon, NavBar, Tab, Tabs, Field, Switch, DatetimePicker, Popup, List, PullRefresh, Form, Popover, Picker, RadioGroup, Radio } from 'vant'

Vue.use(Button)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Icon)
Vue.use(NavBar)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Field)
Vue.use(Switch)
Vue.use(DatetimePicker)
Vue.use(Popup)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Form)
Vue.use(Popover)
Vue.use(Picker)
Vue.use(Radio)
Vue.use(RadioGroup)