
import state from './state.js'
import actions from './actions.js'
import getters from './getters.js'
import mutations from './mutations.js'
import createPersistedState from 'vuex-persistedstate'
import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)
const store = new Vuex.Store({
  state: state,
  actions:actions,
  getters:getters,
  mutations:mutations,
  plugins: [
        createPersistedState()
  ]
})
// vuex热更新
if (module.hot) {
	module.hot.accept([
		'./state.js',
		'./mutations.js',
		'./actions.js',
		'./getters.js',
	], () => {
		const newState = require('./state.js').default
		const newMutations = require('./mutations.js').default
		const newActions = require('./actions.js').default
		const newGetters = require('./getters.js').default

		store.hotUpdate({
			state: newState,
			mutations: newMutations,
			getters: newGetters,
			actions: newActions,
		})
	})
}
export default store