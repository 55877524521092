import Vue from 'vue'
import App from './App.vue'
import routes from './router'
import store from './store'
import actions from "./shared/actions";
import util from '@/utils/util.js'

// import 'whatwg-fetch';
// import 'custom-event-polyfill';
// import 'core-js/stable/promise';
// import 'core-js/stable/symbol';
// import 'core-js/stable/string/starts-with';
// import 'core-js/web/url';


import './vantUi'
import './assets/font/iconfont.css'

import VueRouter from 'vue-router'
import 'lib-flexible'
// 复制内容到剪贴板功能插件
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.config.productionTip = false

let instance = null

function render (props = {}) {
  // 接收主应用下发的base路由
  const { container, routerBase } = props

  Vue.use(VueRouter)
  const router = new VueRouter({
    base: window.__POWERED_BY_QIANKUN__ ? routerBase : '/',
    mode: 'history',
    routes
  })

  /*路由拦截*/
  router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    	// let shopName = localStorage.getItem('shopNameTitle')
    	// 更改标题
    	// let title = to.meta.title?shopName+' - '+to.meta.title:"千里眼-大旗软件"
      if(to.name=='pay'){
        return location.href = 'tel://' + '073182297060'
      }
      let title = to.meta.title?to.meta.title:"千里眼-大旗软件"
    	util.title(title)
      next()
  })

  instance = new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount(container ? container.querySelector('#history-app-sub') : '#history-app-sub') // 这里匹配的是public文件夹下面的index.html的id
}

// 独立运行时
if (!window.__POWERED_BY_QIANKUN__) {
  render()
} else {
  // eslint-disable-next-line camelcase,no-undef
  __webpack_public_path__ = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__
}

export async function bootstrap () {
  console.log('[vue] vue app bootstraped')
}

export async function mount (props) {
  actions.setActions(props);
  console.log('[vue] props from main framework', props)
  render(props)
}

export async function unmount () {
  instance.$destroy()
  instance.$el.innerHTML = ''
  instance = null
}
