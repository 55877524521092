<template>
  <div class="subApp">
    <!-- 内容容器 -->
    <div class="app-container"><router-view /></div>
    <!-- tabBar菜单 -->
    <tabbar v-if="$route.path=='/index'||$route.path=='/Message'||$route.path=='/send'||$route.path=='/pay'"></tabbar>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  name: 'app',
  data() {
    return {};
  },
  components: {
    tabbar: () => import('@/components/tabbar')
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        Toast('提示内容');
      })();
    };
  }
};
</script>

<style lang="scss">
  
  // #app{
  //   /deep/.load{
  //     padding: 1px !important;
  //   }
  // }
  
</style>
