const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/index')
  },
  {
    path: '/index',
    name: 'index',
    meta:{
      title:'首页'
    },
    component: () => import('@/views/index/index')
  },
  {
    path: '/newNote',
    name: 'newNote',
    meta:{
      title:'新建短信'
    },
    component: () => import('@/views/index/newNote')
  },
  {
    path: '/memberScreening',
    name: 'memberScreening',
    meta:{
      title:'会员筛选'
    },
    component: () => import('@/views/index/memberScreening')
  },
  {
    path: '/sendRecord',
    name: 'sendRecord',
    meta:{
      title:'发送记录'
    },
    component: () => import('@/views/index/sendRecord')
  },
  {
    path: '/marketingSMS',
    name: 'marketingSMS',
    meta:{
      title:'营销短信'
    },
    component: () => import('@/views/index/marketingSMS')
  },
  {
    path: '/groupSMS',
    name: 'groupSMS',
    meta:{
      title:'群发短信'
    },
    component: () => import('@/views/index/groupSMS')
  },
  {
    path: '/sendSuccessfully',
    name: 'sendSuccessfully',
    meta:{
      title:'发送成功'
    },
    component: () => import('@/components/sendSuccessfully')
  },
  {
    path: '/cut',
    name: 'cut',
    meta:{
      title:'切换门店'
    },
    component: () => import('@/views/index/cut/cut')
  },
  {
    path: '/Message',
    name: 'Message',
    meta:{
      title:'短信模板'
    },
    component: () => import('@/views/Message/Message')
  },
  {
    path: '/send',
    name: 'send',
    meta:{
      title:'定时发送'
    },
    component: () => import('@/views/send/send')
  },
  {
    path: '/pay',
    name: 'pay',
    meta:{
      title:'在线充值'
    },
    component: () => import('@/views/pay/pay')
  },
  {
    path: '/Marketing',
    name: 'Marketing',
    meta:{
      title:'短信模板'
    },
    component: () => import('@/views/Message/Marketing_note/Marketing')
  },
  {
    path: '/groupSMSs',
    name: 'groupSMSs',
    meta:{
      title:'群发短信'
    },
    component: () => import('@/views/Message/Marketing_note/groupSMSs')
  },
 {
   path: '/addText',
   name: 'addText',
   meta:{
     title:'添加短信'
   },
   component: () => import('@/views/Message/Marketing_note/addText/addText')
 },
 {
   path: '/addsend',
   name: 'addsend',
   meta:{
     title:'定时发送'
   },
   component: () => import('@/views/send/addsend')
 },
 {
   path: '/editHoliday',
   name: 'editHoliday',
   meta:{
     title:'定时发送'
   },
   component: () => import('@/views/send/editHoliday')
 }
]

export default routes
