let util={}

/**
 * @description 更新标题
 * @param {String} title 标题
 */
util.title = (titleText) =>{
	document.title = titleText
}

/**
 * 判断数据类型
 * @param n
 * @returns {string}
 */
util.varType = (n) =>{
	let typeStr = Object.prototype.toString.call(n)
	let typeName = ''
	switch(typeStr){
		case '[object String]':
			typeName = 'string'
			break
		case '[object Number]':
			typeName = 'number'
			break
		case '[object Boolean]':
			typeName = 'boolean'
			break
		case '[object Undefined]':
			typeName = 'undefined'
			break
		case '[object Object]':
			typeName = 'object'
			break
		case '[object Array]':
			typeName = 'array'
			break
		case '[object Null]':
			typeName = 'null'
			break
		case '[object RegExp]':
			typeName = 'RegExp'
			break
		case '[object Symbol]':
			typeName = 'symbol'
			break
		case '[object JSON]':
			typeName = 'json'
			break
		case '[object Math]':
			typeName = 'math'
			break
		case '[object Date]':
			typeName = 'Date'
			break
		default:
			typeName = 'object'
	}
	return typeName
}

/**
 * 日期格式化
 * @param n
 * @returns {string}
 */
util.formatDate = (date,fmt) =>{
	fmt = fmt || 'yyyy-MM-dd hh:mm:ss'
	if(util.varType(date) !== 'Date'){
		if(util.varType(date) === 'string'){
			date = date.replace(/-/g,'/')
		}
		date = new Date(date)
	}
	let padLeftZero = (str) =>{
		return ('00' + str).substr(str.length)
	}
	if(/(y+)/.test(fmt)){
		fmt = fmt.replace(RegExp.$1,(date.getFullYear() + '').substr(4 - RegExp.$1.length))
	}
	let o = {
		'M+':date.getMonth() + 1,
		'd+':date.getDate(),
		'h+':date.getHours(),
		'm+':date.getMinutes(),
		's+':date.getSeconds()
	}
	for(let k in o){
		if(new RegExp(`(${k})`).test(fmt)){
			let str = o[k] + ''
			fmt = fmt.replace(RegExp.$1,RegExp.$1.length === 1?str:padLeftZero(str))
		}
	}
	return fmt
}

/**
 * JS获取时间(今天，昨天，近七天，近三天，近一个月，近六个月)
 * @param (当前时间,间隔天数,true或false时间方向)
 * @returns {string,string}
 * @examples 获取当前日期（2020-09-9）
     var date = new Date();
     var list = this.getDateRange(date,6,true)
     console.log("获取近一周日期范围：\n开始日期:"+list[0]+";\n结束日期:"+list[1]);

     var list = this.getDateRange(date,30,true)
     console.log("获取近一个月日期范围：\n开始日期:"+list[0]+";\n结束日期:"+list[1]);

     var list = this.getDateRange(date,0,true)
     console.log("获取今天日期范围：\n开始日期:"+list[0]+";\n结束日期:"+list[1]);

     var list = this.getDateRange(date,1,true)
     console.log("获取昨天日期范围：\n开始日期:"+list[0]+";\n结束日期:"+list[0]);

     var list = this.getDateRange(date,6,false)
     console.log("获取下一周日期范围：\n开始日期:"+list[0]+";\n结束日期:"+list[1]);

     var list = this.getDateRange(date,30,false)
     console.log("获取下一个月日期范围：\n开始日期:"+list[0]+";\n结束日期:"+list[1]);

 */
util.getDateRange=(dateNow,intervalDays,bolPastTime)=>{
    let oneDayTime = 24 * 60 * 60 * 1000;
    let list = [];
    let lastDay;

    if(bolPastTime == true){
        lastDay = new Date(dateNow.getTime() - intervalDays * oneDayTime);
        list.push(util.formatDate(lastDay,'yyyy-MM-dd'));
        list.push(util.formatDate(dateNow,'yyyy-MM-dd'));
    }else{
        lastDay = new Date(dateNow.getTime() + intervalDays * oneDayTime);
        list.push(util.formatDate(dateNow,'yyyy-MM-dd'));
        list.push(util.formatDate(lastDay,'yyyy-MM-dd'));
    }
    return list;
 }

export default util
